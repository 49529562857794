import React, { useState, useEffect } from 'react';
import ContentfulPicture from '../../ContentfulPicture';

const INTERVAL = 10000; // 10 seconds

const ImageCrossfade = ({ images }: { images: string[] }) => {
  const [selected, setSelected] = useState(0);
  const [zoom, setZoom] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const handleVisibilityChange = () => {
    setHidden(document.hidden);
  };

  const flipZoom = () => {
    setTimeout(() => setZoom(!zoom), 200);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Set loaded after 1 second to load remaining images lazily
    setTimeout(() => setLoaded(true), 5000);

    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  useEffect(() => {
    let timeout;
    if (!hidden) {
      timeout = setTimeout(() => {
        setSelected((selected + 1) % images.length);
        flipZoom();
      }, INTERVAL);
    }

    return () => clearTimeout(timeout);
  }, [hidden, selected, zoom]);

  const loadedImages = loaded ? images : [images[0]];

  return (
    <div className="cycler">
      {loadedImages.map((image, i) => (
        <ContentfulPicture
          key={i}
          className="crossfade"
          src={image}
          style={{
            opacity: selected === i ? 1 : 0,
            transform: zoom ? 'scale(1.2)' : 'scale(1)',
          }}
        />
      ))}
    </div>
  );
};

export default ImageCrossfade;

import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import Map from '../common/Map';

export default class MapContainer extends React.Component {
  static propTypes = {
    developments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      })
    ).isRequired,
    onMarkerClick: PropTypes.func.isRequired,
  };

  state = {
    mobile: false,
    loaded: false,
  };

  componentDidMount() {
    if (window.innerWidth < 600) {
      this.setState({ mobile: true });
    }
  }

  onChangeVisibility = (isVisible) => {
    if (isVisible && this.state.loaded === false) {
      this.setState({ loaded: true });
    }
  };

  render() {
    const { developments, onMarkerClick } = this.props;
    const { mobile } = this.state;
    return (
      <section className="map" style={{ marginBottom: '20px' }}>
        <VisibilitySensor
          onChange={this.onChangeVisibility}
          offset={{ top: -300 }}
          partialVisibility
          active={!this.state.loaded}
        >
          <div className="heading">
            <div className="container">
              <h2>
                Our Homes{' '}
                <span className="small" style={{ color: '#B28F9C', fontSize: 26 }}>
                  Buckinghamshire, Surrey, Kent, Sussex, Hampshire, Berkshire, Oxfordshire & London
                </span>
              </h2>
            </div>
          </div>
        </VisibilitySensor>

        <div>
          <div className="mapContainer">
            <div className="mapKey">
              <div>
                <img
                  src="/images/house_available.png"
                  alt=""
                  className="keyIcon"
                  loading="lazy"
                  width={32}
                  height={24}
                />{' '}
                Available
              </div>
              <div>
                <img
                  src="/images/house_forthcoming.png"
                  alt=""
                  loading="lazy"
                  className="keyIcon"
                  width={32}
                  height={24}
                />{' '}
                Forthcoming
              </div>
            </div>

            {this.state.loaded && (
              <Map
                zoom={mobile ? 8 : 9}
                markers={developments.map((d) => ({
                  title: d.name,
                  position: { lat: Number(d.latitude), lng: Number(d.longitude) },
                  icon: {
                    scaledSize: {
                      width: 32,
                      height: 23,
                      equals: () => true,
                    },
                    url:
                      d.status === 'available'
                        ? '/images/house_available.png'
                        : '/images/house_forthcoming.png',
                  },
                  onClick: () => onMarkerClick(d),
                  defaultAnimation: 2,
                }))}
              />
            )}
          </div>
        </div>
        {/* <div className="col-md-3 row" style={{background:'#f5f5f5', height:'500px'}}>
          <div className="row">
            <div className="col-md-12">
            <h3>Merlin House</h3>
            <div className="col-md-3">
              <img src="images/house.jpg" className="" alt="Cinque Terre" width="50" height="50" />
            </div>
            <div className="col-md-9">
              <p>One two-bedroom flat and four one-bed flats</p>
            </div>
            </div>

            <div className="col-md-12">
              <h3>Other House</h3>
              <div className="col-md-3">
                <img src="images/house.jpg" className="" alt="Cinque Terre" width="50" height="50" />
              </div>
              <div className="col-md-9">
                <p>One two-bedroom flat and four one-bed flats</p>
              </div>
            </div>
          </div>

          <div className="bottom">
            <div className="col-md-12">
              <p><img src="images/availablehouse.png" style={{marginBottom: '-2px'}} alt="" /> Available&nbsp;&nbsp;&nbsp;<img src="images/awaitinghouse.png" style={{marginBottom: '-2px'}} alt="" />
                Forthcoming
              </p>
            </div>
          </div>
        </div> */}
      </section>
    );
  }
}

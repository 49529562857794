import React from 'react';
import classNames from 'classnames';
import { useState } from 'react';
import { useRouter } from 'next/router';
import ImageCrossfade from './ImageCrossfade';

const Header = ({ images }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const router = useRouter();

  const search = (e) => {
    e.preventDefault();
    router.push(`/developments?q=${searchTerm}`);
  };

  return (
    <section className="home-title">
      <ImageCrossfade images={images} />
      <div className="titleBase">
        <div className="container">
          <div className="col-md-3 visible-md visible-lg">
            <img
              src="/images/aspire.png"
              alt="aspire..."
              style={{ width: 180 }}
              width={180}
              height={64}
            />
          </div>
          <div className="col-md-9">
            <form onSubmit={search} className="form-horizontal">
              <div className="col-md-9">
                <div className="searchContainer">
                  <label htmlFor="search" className="searchLabel">
                    Find your new home
                  </label>
                  <input
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    className="form-control"
                    name="q"
                    placeholder="Location or Development"
                    id="search"
                    autoComplete="off"
                  />
                  {/* <div id="suggestions" style={{ display: 'none' }} /> */}
                </div>
              </div>
              <div>
                <div className={classNames('col-md-2', 'searchButton')}>
                  <input type="submit" value="Search" className="form-control btn btn-primary" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
